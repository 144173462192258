import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Col,
  Button,
  Badge,
} from "reactstrap";
import { getPasskeyJWTAsync } from "../../../webauthn/securityStepUp";
import { getAuthorization } from "webauthn/session";

const ListInvoices = () => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  const history = useHistory();

  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    (async () => {
      if (principalLegalEntity?.agent_legal_entity?.customer_uuid) {
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/invoice`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
              "x-principal-legal-entity-uuid":
                principalLegalEntity.legal_entity_uuid,
            },
          }
        );
        const json = await response.json();
        setInvoices(json.invoices);
      } else {
        const authorization = await getAuthorization();
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/invoice`,
          {
            headers: {
              Authorization: authorization,
            },
          }
        );
        const json = await response.json();
        setInvoices(json.invoices);
      }
    })();
  }, [setInvoices]);

  const mapInvoiceStatus = (status) => {
    const invoiceStatusMap = {
      DRAFT: "Draft",
      SENT: "Sent",
    };

    return invoiceStatusMap[status];
  };

  const mapInvoiceStatusIcon = (status) => {
    const invoiceStatusIconMap = {
      DRAFT: "bg-info",
      SENT: "bg-success",
    };

    return invoiceStatusIconMap[status];
  };

  const duplicateInvoice = async (invoiceUUID) => {
    const principalLegalEntity = JSON.parse(
      localStorage.getItem("principalLegalEntity")
    );

    if (principalLegalEntity?.legal_entity_uuid) {
      const response = await fetch(
        `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/invoice/${invoiceUUID}/duplicate`,
        {
          headers: {
            Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
            "x-principal-legal-entity-uuid":
              principalLegalEntity.legal_entity_uuid,
          },
          method: "post",
        }
      );
      if (response.ok) {
        const json = await response.json();
        history.push(`/dashboard/live-invoice/${json.invoice_uuid}`);
      } else {
        alert("Could not duplicate invoice");
      }
    } else {
      const authorization = await getAuthorization();
      const response = await fetch(
        `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/invoice/${invoiceUUID}/duplicate`,
        {
          headers: {
            Authorization: authorization,
          },
          method: "post",
        }
      );
      if (response.ok) {
        const json = await response.json();
        history.push(`/dashboard/live-invoice/${json.invoice_uuid}`);
      } else {
        alert("Could not duplicate invoice");
      }
    }
  };

  return (
    <>
      {/* Page content */}
      <Container className="mt--5" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Invoices</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="success"
                      onClick={() =>
                        history.push("/dashboard/live-invoice/add")
                      }
                      size="sm"
                    >
                      Add Invoice
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Invoice Date</th>
                    <th scope="col">Invoice ID</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Created Date</th>
                    <th scope="col">Status</th>
                    <th scope="col" />
                    <th scope="col">Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((invoice, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {DateTime.fromISO(
                            invoice.effective_date
                          ).toLocaleString(DateTime.DATE_MED)}
                        </td>
                        <th scope="row">
                          {invoice.status === "DRAFT" ? (
                            <a href={`/dashboard/live-invoice/${invoice.uuid}`}>
                              {invoice.invoice_serial}
                            </a>
                          ) : (
                            invoice.invoice_serial
                          )}
                        </th>
                        <td>
                          {new Intl.NumberFormat("en-AU", {
                            style: "currency",
                            currency: "AUD",
                          }).format(
                            parseFloat(invoice.invoice_amount ?? 0) +
                              parseFloat(invoice.tax_amount ?? 0)
                          )}
                        </td>
                        <td>
                          {invoice.counterparty_business_name
                            ? invoice.counterparty_business_name
                            : `${
                                invoice.counterparty_first_name
                                  ? invoice.counterparty_first_name
                                  : ""
                              } ${
                                invoice.counterparty_last_name
                                  ? invoice.counterparty_last_name
                                  : ""
                              }`}
                          {invoice.business_name
                            ? invoice.business_name
                            : `${
                                invoice.first_name ? invoice.first_name : ""
                              } ${invoice.last_name ? invoice.last_name : ""}`}
                        </td>
                        <td>
                          {DateTime.fromISO(invoice.created_at).toLocaleString(
                            DateTime.DATETIME_MED
                          )}
                        </td>
                        <td>
                          <Badge color="" className="badge-dot">
                            <i
                              className={mapInvoiceStatusIcon(invoice.status)}
                            />
                            {mapInvoiceStatus(invoice.status)}
                          </Badge>
                        </td>
                        <td className="text-center">
                          {invoice.status === "SENT" ||
                          invoice.status === "PAID" ? (
                            <Button
                              color="success"
                              size="sm"
                              href={`${window.origin}/customer/invoice/${invoice.uuid}`}
                              target="_blank"
                            >
                              View Invoice
                            </Button>
                          ) : undefined}
                        </td>
                        <td className="text-left">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                              data-testid={`invoice_options_dropdown_${index}`}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {invoice.status === "DRAFT" ? (
                                <DropdownItem
                                  onClick={() =>
                                    history.push(
                                      `/dashboard/live-invoice/${invoice.uuid}`
                                    )
                                  }
                                >
                                  Edit Invoice
                                </DropdownItem>
                              ) : undefined}
                              {invoice.status === "SENT" ? (
                                <DropdownItem
                                  onClick={() => duplicateInvoice(invoice.uuid)}
                                >
                                  Duplicate Invoice
                                </DropdownItem>
                              ) : undefined}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ListInvoices;
