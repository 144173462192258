import { Hub } from "aws-amplify/utils";
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";

export const presentAuthorities = async () => {
  const authorization = await getAuthorization();

  const response = await fetch(
    `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/agent-authority`,
    {
      headers: {
        Authorization: authorization,
        "Content-Type": "application/json",
      },
      method: "GET",
    }
  );
  if (response.ok) {
    const authoritiesJSON = await response.json();

    if (authoritiesJSON.authorities.length === 0) {
      /*
       * If there are no authorities, we need to set the principal to self
       *  and then go straight to the dashboard
       */
      const principalLegalEntity = {
        legal_entity_uuid: authoritiesJSON.customer?.legal_entity_uuid,
        legal_entity_name: authoritiesJSON.customer?.legal_entity_name,
        customer_uuid: authoritiesJSON.customer?.customer_uuid,
      };
      localStorage.setItem(
        "principalLegalEntity",
        JSON.stringify(principalLegalEntity)
      );

      // Go straight to the dashboard as the Customer does not have any Authorities
      window.location.href = "/dashboard";
    } else {
      window.location.href = "/authorities";
    }
  }
};

Hub.listen("auth", async ({ payload }) => {
  const { event } = payload;

  if (event === "signedIn") {
    const authenticationResumptionRoute = localStorage.getItem(
      "authenticationResumptionRoute"
    );
    if (authenticationResumptionRoute) {
      localStorage.removeItem("authenticationResumptionRoute");
      window.location.href = authenticationResumptionRoute;
    } else {
      await presentAuthorities();
    }
  }
});

let email;

export const getAuthorization = async () => {
  try {
    if (await getCurrentUser()) {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};

      if (accessToken) {
        const expired = Math.floor(Date.now() / 1000) > accessToken.payload.exp;

        if (accessToken && !expired) {
          email = idToken.payload.email;

          return accessToken;
        }
      }
    }
  } catch (e) {
    if (e.name === "UserUnAuthenticatedException") {
      window.location.href = "/sign-in";
    } else {
      console.log(e);
    }
  }
};

export const getSessionEmail = () => {
  return email;
};
