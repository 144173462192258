import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Table,
} from "reactstrap";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// core components
import FlowNavbar from "components/Navbars/FlowNavbar";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import ProgressBar from "../../components/progressBar";

import CheckoutForm from "../../components/checkoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Checkout = () => {
  const planId = localStorage.getItem("planId");
  const businessNameApplicationId = localStorage.getItem(
    "businessNameApplicationId"
  );
  const abnApplicationId = localStorage.getItem("abnApplicationId");
  const companyApplicationId = localStorage.getItem("companyApplicationId");

  const [stripeClientSecret, setStripeClientSecret] = useState(undefined);

  const [invoice, setInvoice] = useState([]);
  const [total, setTotal] = useState("");

  useEffect(() => {
    // Record conversion in Google Ads
    window.gtag("event", "conversion", {
      send_to: "AW-312837668/OexRCPGxzaADEKSMlpUB",
    }); // Begin checkout conversion action

    window.scrollTo(0, 0);

    const fetchPaymentToken = async () => {
      const options = {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: localStorage.getItem("email").toLowerCase().trim(),
          plan_id: planId,
          abn_application_id: abnApplicationId,
          business_name_application_id: businessNameApplicationId,
        }),
      };
      const result = await fetch(
        `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/pay`,
        options
      );
      const resultJSON = await result.json();

      setStripeClientSecret(resultJSON.clientSecret);
      setInvoice(resultJSON.invoice);
      setTotal(resultJSON.total);
    };
    fetchPaymentToken();
  }, [
    setStripeClientSecret,
    planId,
    abnApplicationId,
    businessNameApplicationId,
  ]);

  const options = {
    clientSecret: stripeClientSecret,
  };

  const checkoutTitle = (planId) => {
    const titleMap = {
      1: "New ABN Application",
      2: "New ABN Application",
      3: "New ABN Application",
      4: "New ABN Application",
      5: "New Business Name Registration",
      6: "New Company Registration",
    };
    return titleMap[planId];
  };

  return (
    <>
      <FlowNavbar />
      <Container>
        <br />
        <ProgressBar
          title={checkoutTitle(planId)}
          step={planId === "5" ? 3 : 5}
          stepCount={planId === "5" ? 3 : 5}
          stepName="Pay & Lodge"
        />
        <br />
        <Card className="shadow border-1 mb-5">
          <CardTitle className="pt-3 pl-3">Pay & Lodge</CardTitle>
          <CardBody className="p-4">
            <Row className="justify-content-md-center mt-5">
              <Col sm={8}>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoice.map((item) => (
                      <tr key={item.description}>
                        <td>{item.description}</td>
                        <td className="text-right">
                          ${(item.total / 100).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="text-right">
                        <strong>Total</strong>
                      </td>
                      <td className="text-right">
                        <strong>${(total / 100).toFixed(2)}</strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
            <br />
            <br />
            <h4 className="text-center mt-3">
              Use a personal debit or credit card
              <br /> and we'll automatically process your tax write-off
            </h4>
            <Row className="justify-content-md-center mt-5">
              <Col sm={8}>
                {stripeClientSecret ? (
                  <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm
                      total={total}
                      payButtonText={"Pay & Lodge"}
                      returnURL={process.env.REACT_APP_STRIPE_REDIRECT_URL}
                    />
                  </Elements>
                ) : undefined}
              </Col>
            </Row>
            <br />
            <br />
          </CardBody>
        </Card>
      </Container>
      <br />
      <br />
      <SimpleFooter />
    </>
  );
};

export default Checkout;
