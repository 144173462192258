import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Spinner,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import { FaArrowRight } from "react-icons/fa";

import {
  encode as b64URLArrayBufferEncode,
  decode as b64URLArrayBufferDecode,
} from "@qix/base64url-arraybuffer";
import {
  signOut,
  signIn,
  confirmSignIn,
  getCurrentUser,
} from "aws-amplify/auth";

import { webAuthnRegister } from "../../../../webauthn";

import FlowNavbar from "components/Navbars/FlowNavbar";
import ProgressBar from "../../../components/progressBar";
import SimpleFooter from "components/Footers/SimpleFooter.js";

const PrimaryPasskey = (props) => {
  const history = useHistory();

  const stepNumber = 3;

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState(
    localStorage.getItem("email").toLowerCase().trim()
  );
  const [errorMessage, setErrorMessage] = useState();

  const registerUser = async ({ email }) => {
    if (email === "") {
      setErrorMessage("Email is required");
    } else {
      setLoading(true);

      /*
       * NOTE:
       *
       * This is desirable because Amplify version 6 does not permit
       * multiple active sessions (including sessions for account being created
       * and/or during verification)
       *
       * For more information, see:
       * https://github.com/aws-amplify/amplify-js/issues/13070#issuecomment-2275797232
       *
       */
      await signOut({ global: true });

      try {
        const lowerCaseEmail = email.toLowerCase(); // Required for Passkey interoperability
        const result = await webAuthnRegister({
          displayName: lowerCaseEmail,
          name: lowerCaseEmail,
          username: lowerCaseEmail,
        });
        if (result.status === "failed") {
          setErrorMessage(result.message);
        } else {
          setLoading(false);

          // Required to prep verify page
          localStorage.setItem("unverififedEmail", email);

          // Redirect user to verification page
          history.push("/lodge/company-registration/verify-email");
        }
      } catch (e) {
        setErrorMessage(e.message);
      }

      setLoading(false);
    }
  };

  const loginUser = async ({ username }) => {
    localStorage.setItem(
      "authenticationResumptionRoute",
      "/lodge/company-registration/registered-office"
    );

    if (username === "") {
      setErrorMessage("Email cannot be empty");
    } else {
      setLoading(true);

      try {
        const { nextStep } = await signIn({
          username,
          options: {
            authFlowType: "CUSTOM_WITHOUT_SRP",
          },
        });

        if (nextStep.signInStep === "CONFIRM_SIGN_UP") {
          /*
           *  This is the case where a user has navigated away before verifying email
           *
           */
          setLoading(false);
          history.push("/lodge/company-registration/verify-email");
        } else {
          const challenge = nextStep.additionalInfo.challenge;
          const credentialId = nextStep.additionalInfo.credId;
          const publicKeyCred = nextStep.additionalInfo.publicKeyCred;

          const additionalPasskeys = JSON.parse(
            atob(publicKeyCred)
          ).additionalPasskeys;

          let credential;
          if (additionalPasskeys) {
            // Get cred list now
            const additionalDecodedPasskeys = additionalPasskeys.map((key) => {
              return {
                id: b64URLArrayBufferDecode(key.id),
                type: "public-key",
                transports: ["ble", "nfc", "usb", "internal"],
              };
            });

            credential = await navigator.credentials.get({
              publicKey: {
                challenge: b64URLArrayBufferDecode(challenge),
                timeout: 1800000,
                rpId: process.env.REACT_APP_WEBAUTHN_RP_ID,
                userVerification: "preferred",
                allowCredentials: [
                  {
                    id: b64URLArrayBufferDecode(credentialId),
                    type: "public-key",
                    transports: ["ble", "nfc", "usb", "internal"],
                  },
                  ...additionalDecodedPasskeys,
                ],
              },
            });
          } else if (credentialId) {
            credential = await navigator.credentials.get({
              publicKey: {
                challenge: b64URLArrayBufferDecode(challenge),
                timeout: 1800000,
                rpId: process.env.REACT_APP_WEBAUTHN_RP_ID,
                userVerification: "preferred",
                allowCredentials: [
                  {
                    id: b64URLArrayBufferDecode(credentialId),
                    type: "public-key",
                    transports: ["ble", "nfc", "usb", "internal"],
                  },
                ],
              },
            });
          } else {
            credential = await navigator.credentials.get({
              publicKey: {
                challenge: b64URLArrayBufferDecode(challenge),
                timeout: 1800000,
                rpId: process.env.REACT_APP_WEBAUTHN_RP_ID,
                userVerification: "preferred",
              },
            });
          }

          const challengeAnswer = {
            response: {
              clientDataJSON: b64URLArrayBufferEncode(
                credential.response.clientDataJSON
              ),
              authenticatorData: b64URLArrayBufferEncode(
                credential.response.authenticatorData
              ),
              signature: b64URLArrayBufferEncode(credential.response.signature),
              userHandle: b64URLArrayBufferEncode(
                credential.response.userHandle
              ),
            },
          };

          await confirmSignIn({
            challengeResponse: JSON.stringify(challengeAnswer),
          });

          setLoading(false);
        }
      } catch (e) {
        if (e.code === "UserNotConfirmedException") {
          setLoading(false);
          window.location.href = "/verify";
        } else if (e.code === "UserLambdaValidationException") {
          setErrorMessage(
            "Your biometrics are not yet enrolled. Please sign in with a password instead."
          );
        } else {
          setErrorMessage(`Error: ${e}`);
        }
      }

      setLoading(false);
    }
  };

  return (
    <>
      <FlowNavbar />
      <Container>
        <br />
        <ProgressBar
          title={`${localStorage.getItem("companyName")} PTY LTD`}
          step={stepNumber}
          stepCount={5}
          stepName="Registration Deatils"
        />
        <br />

        <div>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              await submitForm();
            }}
          >
            <Card className="text-left shadow border-1 mb-5">
              <CardHeader>Step {stepNumber}. Primary Passkey </CardHeader>
              <CardBody>
                <h3>Register Passkey</h3>
                To secure your company, we require you to create a passkey.
                <br />
                This will ensure only you can authorise changes to the company.
                <br />
                <br />
                <br />
                <br />
                <FormGroup tag="fieldset" row>
                  <Label sm={3} className="text-right d-none d-sm-block">
                    Email
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="text"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => {
                        localStorage.setItem("email", e.target.value);
                        setEmail(e.target.value);
                      }}
                      invalid={errorMessage ? true : false}
                    />
                    <FormFeedback>
                      {errorMessage === "" ? null : (
                        <>
                          <p className="text-left text-danger">
                            {errorMessage}
                            <br />
                            If you have already created an accountedfor passkey,
                            sign in:
                          </p>
                          <Button
                            onClick={() => loginUser({ username: email })}
                            color="info"
                            disabled={loading}
                          >
                            {loading ? (
                              <Spinner
                                animation="border"
                                variant="light"
                                size="sm"
                              />
                            ) : null}{" "}
                            Sign In with Passkey <FaArrowRight />
                          </Button>
                        </>
                      )}
                    </FormFeedback>
                  </Col>
                </FormGroup>
                <br />
              </CardBody>
              <CardFooter className="text-right">
                <Button
                  onClick={() => registerUser({ email })}
                  color="success"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : null}{" "}
                  Create Passkey <FaArrowRight />
                </Button>
              </CardFooter>
            </Card>
          </Form>
        </div>
      </Container>
      <SimpleFooter />
    </>
  );
};

export default PrimaryPasskey;
