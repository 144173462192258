import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Spinner,
  Button,
  Container,
  Row,
  Col,
} from "reactstrap";
import { FaArrowRight } from "react-icons/fa";

import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { AutofillDisabledInput } from "views/components/autofillDisabledInput";

import FlowNavbar from "components/Navbars/FlowNavbar";
import ProgressBar from "../../../components/progressBar";
import SimpleFooter from "components/Footers/SimpleFooter.js";

const RegisteredOffice = (props) => {
  const stepNumber = 3;

  const [loading, setLoading] = useState(false);

  const [submissionAttempted, setSubmissionAttempted] = useState(false);

  // Registered Office
  const [autocompletedAddress, setAutocompletedAddress] = useState(null);
  const [geocodedAddress, setGeocodedAddress] = useState(null);

  const handleAutocompletedAddressChange = async (address) => {
    setAutocompletedAddress(address);
    if (address) {
      const result = await geocodeByPlaceId(address.value.place_id);
      setGeocodedAddress(result);
    }
  };

  const [activelyOccupy, setActivelyOccupy] = useState(true);
  const [otherOccupier, setOtherOccupier] = useState("");
  const [occupiersConsent, setOccupiersConsent] = useState(true);

  const [principalPlaceOfBusiness, setPrincipalPlaceOfBusiness] =
    useState(true);

  // Princpal Place of Business
  const [
    autocompletedPrincipalPlaceOfBusinessAddress,
    setAutocompletedPrincipalPlaceOfBusinessAddress,
  ] = useState(null);
  const [geocodedAddress2, setGeocodedAddress2] = useState(null);

  const handleAutocompletedPrincipalPlaceOfBusinessAddressChange = async (
    address
  ) => {
    setAutocompletedPrincipalPlaceOfBusinessAddress(address);
    if (address) {
      const result = await geocodeByPlaceId(address.value.place_id);
      setGeocodedAddress2(result);
    }
  };

  const [jurisdiction, setJurisdiction] = useState("NSW");

  const submitForm = async () => {
    if (!geocodedAddress) {
      alert("You must select a registered office to continue");
      return;
    }

    const result = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/company-application`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          company_uuid: localStorage.getItem("companyUUID"),
          registered_office: geocodedAddress, // XXX: fix this
          registered_office_occupied: activelyOccupy,
          other_occupier_name: otherOccupier,
          other_principal_place_of_business: geocodedAddress2, // XXX: fix
          jurisdiction: jurisdiction,
        }),
      }
    );
    await result.json();

    window.location = "/lodge/company-registration/share-structure";
  };

  return (
    <>
      <FlowNavbar />
      <Container>
        <br />
        <ProgressBar
          title={`${localStorage.getItem("companyName")} PTY LTD`}
          step={stepNumber}
          stepCount={5}
          stepName="Registration Deatils"
        />
        <br />

        <div>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              await submitForm();
            }}
          >
            <Card className="text-left shadow border-1 mb-5">
              <CardHeader>Step {stepNumber}. Company Details </CardHeader>
              <CardBody>
                <h3>Registered Office</h3>
                The registered office is the address of the company for legal
                purposes.
                <br />
                This address must be within Australia and cannot be a PO Box
                address.
                <br />
                <br />
                <br />
                <FormGroup tag="fieldset" row>
                  <Label sm={3} className="text-right d-none d-sm-block">
                    Address
                  </Label>
                  <Col sm={6}>
                    <GooglePlacesAutocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["au"],
                        },
                        types: ["address"],
                      }}
                      selectProps={{
                        components: { Input: AutofillDisabledInput },
                        isClearable: true,
                        value: autocompletedAddress,
                        onChange: handleAutocompletedAddressChange,
                        placeholder: "Start typing address",
                      }}
                    />
                  </Col>
                </FormGroup>
                <br />
                <hr />
                <h3>Registered Office Occupier</h3>
                Does the company occupy the premises of the registered address?
                <br />
                <br />
                <br />
                <FormGroup tag="fieldset" row>
                  <Label sm={3} className="text-left"></Label>
                  <Col sm={6}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="activelyOccupy"
                          checked={activelyOccupy}
                          onChange={() => setActivelyOccupy(true)}
                        />
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="activelyOccupy"
                          checked={!activelyOccupy}
                          onChange={() => setActivelyOccupy(false)}
                        />
                        No
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>
                {!activelyOccupy ? (
                  <>
                    <FormGroup tag="fieldset" row>
                      <Label sm={3} className="text-right">
                        Other Occupier
                      </Label>
                      <Col sm={6}>
                        <Input
                          type="text"
                          placeholder="Name"
                          value={otherOccupier}
                          onChange={(e) => {
                            localStorage.setItem(
                              "otherOccupier",
                              e.target.value
                            );
                            setOtherOccupier(e.target.value);
                          }}
                          valid={otherOccupier !== ""}
                          invalid={submissionAttempted && !otherOccupier}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup tag="fieldset" row>
                      <Label sm={3} className="text-right">
                        Occupier's Consent
                      </Label>
                      <Col sm={6}>
                        <FormGroup check>
                          <Input
                            type="checkbox"
                            checked={occupiersConsent}
                            onChange={() =>
                              setOccupiersConsent(!occupiersConsent)
                            }
                            invalid={!occupiersConsent}
                            name="occupiersConsent"
                          />
                          The occupier consents to being the Registered Office
                          <FormFeedback>
                            You must be an Australian resident for tax purposes
                            to use this form
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                  </>
                ) : (
                  <></>
                )}
                <hr />
                <h3>Principal place of business</h3>
                Is the principal place of business the registered office?
                <br />
                <br />
                <br />
                <FormGroup tag="fieldset" row>
                  <Label sm={3} className="text-left"></Label>
                  <Col sm={6}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="principalPlaceOfBusiness"
                          checked={principalPlaceOfBusiness}
                          onChange={() => setPrincipalPlaceOfBusiness(true)}
                        />
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="principalPlaceOfBusiness"
                          checked={!principalPlaceOfBusiness}
                          onChange={() => setPrincipalPlaceOfBusiness(false)}
                        />
                        No
                      </Label>
                    </FormGroup>
                  </Col>
                </FormGroup>
                {!principalPlaceOfBusiness ? (
                  <>
                    <FormGroup tag="fieldset" row>
                      <Label sm={3} className="text-right d-none d-sm-block">
                        Address
                      </Label>
                      <Col sm={6}>
                        <GooglePlacesAutocomplete
                          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                          autocompletionRequest={{
                            types: ["address"],
                          }}
                          selectProps={{
                            components: { Input: AutofillDisabledInput },
                            isClearable: true,
                            value: autocompletedPrincipalPlaceOfBusinessAddress,
                            onChange:
                              handleAutocompletedPrincipalPlaceOfBusinessAddressChange,
                            placeholder: "Start typing address",
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </>
                ) : (
                  <></>
                )}
                <br />
                <hr />
                <h3>State of Registration</h3>
                <br />
                <br />
                <FormGroup tag="fieldset" row>
                  <Label as="legend" sm={3} className="text-right">
                    Which state should the company be registered in
                  </Label>
                  <Col sm={6}>
                    <Input
                      type="select"
                      onChange={(e) => setJurisdiction(e.target.value)}
                      value={jurisdiction}
                    >
                      <option value="NSW">New South Wales</option>
                      <option value="VIC">Victoria</option>
                      <option value="QLD">Queensland</option>
                      <option value="WA">Western Australia</option>
                      <option value="SA">South Australia</option>
                      <option value="NT">Northern Territory</option>
                      <option value="ACT">Australian Capital Territory</option>
                      <option value="TAS">Tasmania</option>
                    </Input>
                  </Col>
                </FormGroup>
              </CardBody>
              <CardFooter className="text-right">
                <Button type="submit" color="success" disabled={loading}>
                  {loading ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : null}{" "}
                  Continue <FaArrowRight />
                </Button>
              </CardFooter>
            </Card>
          </Form>
        </div>
      </Container>
      <SimpleFooter />
    </>
  );
};

export default RegisteredOffice;
