import { useState, useEffect } from "react";
import { NavLink as NavLinkRRD, Link, useHistory } from "react-router-dom";

import { signOut } from "aws-amplify/auth";

import { PropTypes } from "prop-types";

// reactstrap components
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Badge,
  Spinner,
} from "reactstrap";

import { getAuthorization, getSessionEmail } from "webauthn/session";
import {
  getPasskeyJWTAsync,
  passkeyAssertion,
} from "../../webauthn/securityStepUp";

const Sidebar = (props) => {
  const history = useHistory();

  const [collapseOpen, setCollapseOpen] = useState();
  // verifies if routeName is the one active (in browser input)
  //const activeRoute = (routeName) => {
  //  return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  //};
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return !prop.invisible &&
        (prop.path === "/scheduling"
          ? props.features.showSchedulingTab?.enabled
          : true) &&
        (prop.path === "/company"
          ? props.features.showCompanyTab?.enabled &&
            props.businessProfile.is_company
          : true) &&
        (prop.path === "/payment-accounts"
          ? props.features.showPaymentsTab?.enabled
          : true) ? (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active"
          >
            {prop.reactIcon ? prop.reactIcon : <i className={prop.icon} />}
            {prop.name}
            {prop.path === "/scheduling" &&
            props.features.showSchedulingTab?.enabled ? (
              <Badge className="text-uppercase ml-3" color="primary" pill>
                beta
              </Badge>
            ) : undefined}
          </NavLink>
        </NavItem>
      ) : undefined;
    });
  };

  const { routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  const [customer, setCustomer] = useState();
  const [authorities, setAuthorities] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const authorization = await getAuthorization();

      const authoritiesResponse = await fetch(
        `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/agent-authority`,
        {
          headers: {
            Authorization: authorization,
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );
      const authoritiesJSON = await authoritiesResponse.json();
      setCustomer(authoritiesJSON.customer);
      setAuthorities(authoritiesJSON.authorities);
      setLoading(false);
    })();
  }, [setCustomer, setAuthorities, setLoading]);

  const actAsCustomer = async () => {
    localStorage.removeItem("securityStepUp");

    const principalLegalEntity = {
      legal_entity_uuid: customer?.legal_entity_uuid,
      legal_entity_name: customer?.legal_entity_name,
      customer_uuid: customer?.customer_uuid,
    };
    localStorage.setItem(
      "principalLegalEntity",
      JSON.stringify(principalLegalEntity)
    );

    localStorage.setItem("currentLegalEntityUUID", customer.legal_entity_uuid);
    window.location.reload();
  };

  const changeAgentLegalEntity = async (newLegalEntityUUID) => {
    if (
      newLegalEntityUUID !==
      props.businessProfile.principal_legal_entity?.legal_entity_uuid
    ) {
      // Authenticate the customer with biometrics, before they can act on behalf of a different legal entity
      //await passkeyAssertion({ email: getSessionEmail() });

      // NOTE: remember you can't switch TO being the customer entity here, so this is correct

      await getPasskeyJWTAsync();
    }

    const principalLegalEntity = {
      legal_entity_uuid: newLegalEntityUUID,
      legal_entity_name: "",
      agent_legal_entity: {
        legal_entity_uuid: customer?.legal_entity_uuid,
        legal_entity_name: customer?.legal_entity_name,
        customer_uuid: customer?.customer_uuid,
      },
    };
    localStorage.setItem(
      "principalLegalEntity",
      JSON.stringify(principalLegalEntity)
    );

    localStorage.setItem("securityStepUp", "required");
    localStorage.setItem("currentLegalEntityUUID", newLegalEntityUUID);
    window.location.reload();
  };

  const addNewBusiness = async () => {
    alert("Coming Soon!");
  };

  const signOutClicked = async () => {
    localStorage.removeItem("principalLegalEntity"); // XXX: is this necessary?
    try {
      await signOut({ global: true });
      history.push("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="lg"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
          data-testid="mobile-nav-menu-button"
        >
          <span className="navbar-toggler-icon" />
        </button>
        {!props.features.showLegalEntityDropdownSelector?.enabled ? (
          <NavbarBrand
            className="pt-0"
            {...navbarBrandProps}
            style={{ margin: "0 auto" }}
          >
            accountedfor.com.au
          </NavbarBrand>
        ) : (
          <>
            {/* Entity Selector */}
            <Nav className="align-items-center d-xs-flex">
              <UncontrolledDropdown nav>
                <DropdownToggle className="ml--2" nav>
                  <button
                    className="btn btn-secondary dropdown-toggle"
                    disabled={loading}
                    style={{
                      textOverflow: "ellipsis",
                      maxWidth: 200,
                      overflow: "hidden",
                    }}
                  >
                    {loading ? (
                      <span className="text-muted">
                        <Spinner size="sm" /> Loading
                      </span>
                    ) : props.businessProfile.principal_legal_entity
                        ?.legal_entity_name ? (
                      props.businessProfile.principal_legal_entity
                        ?.legal_entity_name
                    ) : (
                      getSessionEmail()
                    )}
                  </button>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow ml-3">
                  <DropdownItem
                    onClick={() => actAsCustomer()}
                    style={{
                      textOverflow: "ellipsis",
                      maxWidth: 220,
                      overflow: "hidden",
                    }}
                  >
                    {customer?.legal_entity_name
                      ? customer.legal_entity_name
                      : getSessionEmail()}
                  </DropdownItem>
                  <DropdownItem divider />
                  {authorities.length > 0 ? (
                    authorities?.map((authority, index) => {
                      return (
                        <DropdownItem
                          key={index}
                          onClick={() =>
                            changeAgentLegalEntity(authority.legal_entity_uuid)
                          }
                          style={{
                            textOverflow: "ellipsis",
                            maxWidth: 220,
                            overflow: "hidden",
                          }}
                        >
                          {authority.legal_entity_name}
                        </DropdownItem>
                      );
                    })
                  ) : (
                    <DropdownItem
                      onClick={() => addNewBusiness()}
                      style={{
                        textOverflow: "ellipsis",
                        maxWidth: 220,
                        overflow: "hidden",
                      }}
                      className="text-muted"
                    >
                      <i className="ni ni-fat-add text-muted" />
                      Add Business
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </>
        )}
        {/* User */}
        <Nav className="align-items-center d-md-none"></Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              <Col className="collapse-brand" xs="6">
                accountedfor.com.au
              </Col>
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
          <Nav className="mb-md-3" navbar>
            <NavItem className="active-pro active">
              <NavLink
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  signOutClicked();
                }}
              >
                <i className="ni ni-user-run" />
                Sign out
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
