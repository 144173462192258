import React from "react";
import { Navbar, Nav, Container } from "reactstrap";

import { getSessionEmail } from "webauthn/session";

const MerchantNavbar = (props) => {
  const email = getSessionEmail();

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <h4 className="mb-0 text-white text-uppercase d-none d-md-block">
            <span id="legal-entity-name">
              {props.businessProfile.principal_legal_entity?.legal_entity_name
                ? props.businessProfile.principal_legal_entity
                    ?.legal_entity_name
                : getSessionEmail()}
            </span>
            <br />
            <span id="business-registration-number">
              ABN:{" "}
              {props.businessProfile.business_registration?.abn
                ? props.businessProfile.business_registration?.abn
                : "PENDING"}
            </span>
          </h4>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <h6 className="text-white font-weight-bold">{email}</h6>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default MerchantNavbar;
