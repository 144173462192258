import React, { useState, useEffect } from "react";

//import { Primer } from "@primer-io/checkout-web/dist/Primer.js";
import { Primer } from "@primer-io/checkout-web/dist/Primer";

import { useParams } from "react-router-dom";
import { Button, Row, Col } from "reactstrap";
import { FaRegCheckCircle } from "react-icons/fa";

import styled from "styled-components";

//import { Elements } from "@stripe/react-stripe-js";
//import { loadStripe } from "@stripe/stripe-js";

//import CheckoutForm from "../../../views/components/checkoutForm";

//const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, { stripeAccount: 'acct_1OjGMTR9pX4q0ayC' });

const BigTick = styled(FaRegCheckCircle)`
  font-size: 8rem;
  color: #28a745;
`;

const LiveInvoiceMonoovaPay = (props) => {
  const params = useParams();

  const [invoice, setInvoice] = useState(props.invoice);

  const [primerClientToken, setPrimerClientToken] = useState();

  const startPrimer = async (clientToken) => {
    /*
     *  Start Monoova/Primer Payment Card Processing
     *  override
     */
    //alert(primerClientToken)

    const headless = await Primer.createHeadless(clientToken);

    async function configureCardForm(headless, paymentMethod) {
      /////////////////////////////////////
      ////                             ////
      ////        Card Payments        ////
      ////                             ////
      /////////////////////////////////////

      const container = document.getElementById("card-container");

      const cardFlexRow = document.getElementById("card-flex-row");
      const cardFlexCol = document.getElementById("card-flex-col");
      const cardholderFlexItem = document.getElementById(
        "cardholder-flex-item"
      );
      const actionFlexRow = document.getElementById("action-flex-row");

      // Create containers for your hosted inputs
      const cardNumberInputId = "checkout-card-number-input";
      const cardNumberInputEl = document.createElement("div");
      cardNumberInputEl.setAttribute("id", cardNumberInputId);
      //cardNumberInputEl.style.height = "24px";
      cardNumberInputEl.style.flex = "2";
      cardNumberInputEl.style.paddingRight = "10px";

      const cardExpiryInputId = "checkout-card-expiry-input";
      const cardExpiryInputEl = document.createElement("div");
      cardExpiryInputEl.setAttribute("id", cardExpiryInputId);
      //cardExpiryInputEl.style.height = "24px";
      cardExpiryInputEl.style.flex = "1";
      cardExpiryInputEl.style.paddingRight = "10px";

      const cardCvvInputId = "checkout-card-cvv-input";
      const cardCvvInputEl = document.createElement("div");
      cardCvvInputEl.setAttribute("id", cardCvvInputId);
      //cardCvvInputEl.style.height = "24px";
      cardCvvInputEl.style.flex = "1";
      //cardCvvInputEl.style.padding = "5px";

      const cardHolderInputId = "checkout-card-holder-input";
      const cardHolderInputEl = document.createElement("input");
      //cardCvvInputEl.style.height = "24px";
      cardHolderInputEl.setAttribute("id", cardHolderInputId);
      cardHolderInputEl.setAttribute("placeholder", "Cardholder Name");
      cardHolderInputEl.style.width = "100%";
      cardHolderInputEl.style.padding = "5px";
      cardHolderInputEl.style.border = "1px solid rgb(0 0 0 / 20%)";
      cardHolderInputEl.style.borderRadius = "2px";

      const submitButton = document.createElement("input");
      const buttonId = "submit-button";
      submitButton.setAttribute("type", "button");
      submitButton.setAttribute("id", buttonId);
      submitButton.value = "Pay with Card";

      submitButton.style.color = "#fff";
      submitButton.style.backgroundColor = "#2dce89";
      submitButton.style.border = "1px solid transparent";
      submitButton.style.borderRadius = "4px";
      submitButton.style.borderColor = "#2dce89";
      submitButton.style.boxShadow =
        "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)";
      submitButton.style.padding = "10px 20px 10px 20px";


      // Add them to your container
      cardFlexCol.append(cardNumberInputEl, cardExpiryInputEl, cardCvvInputEl);
      cardholderFlexItem.append(cardHolderInputEl);
      actionFlexRow.append(submitButton);

      //const baseStyles = {
      const style = {
        /*
        input: {
          base: {
            height: "24px",
            border: "1px solid rgb(0 0 0 / 60%)",
            borderRadius: "2px",
            padding: "2px",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            //fontFamily
          },
        },*/
        inputLabel: {
          fontFamily: "courier new",
          color: "#000000",
        },
        input: {
          base: {
            //width: "100px",

            /*borderStyle: "none none solid none",
              borderColor: "#000000",
              borderRadius: "0px",
              boxShadow: "0px",
              background: "#ffffff",
              fontFamily: "courier new",
              paddingHorizontal: "0px",
              lineHeight: "",
              color: "#000000",
              placeholder: {
                color: "#e1deda",
              },
              */
            //height: "24px",
            border: "1px solid rgb(0 0 0 / 20%)",
            borderRadius: "2px",
            padding: "5px",
            //boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
            marginLeft: "0px",
            //marginRight: "5px",
            width: "100%",
          },
        },
      };

      // Create the payment method manager
      console.log("Creating Card payment method manager");
      const cardManager = await headless.createPaymentMethodManager(
        "PAYMENT_CARD"
      );

      // Create the hosted inputs
      console.log("Creating Card hosted inputs");
      const { cardNumberInput, expiryInput, cvvInput } =
        cardManager.createHostedInputs();

      await Promise.all([
        cardNumberInput.render(cardNumberInputId, {
          placeholder: "1234 1234 1234 1234",
          ariaLabel: "Card number",
          style,
        }),
        expiryInput.render(cardExpiryInputId, {
          placeholder: "MM/YY",
          ariaLabel: "Expiry date",
          style,
        }),
        cvvInput.render(cardCvvInputId, {
          placeholder: "123",
          ariaLabel: "CVV",
          style,
        }),
      ]);

      // Set the cardholder name if it changes
      document
        .getElementById(cardHolderInputId)
        .addEventListener("change", (e) => {
          cardManager.setCardholderName(e.target.value);
        });

      // Configure event listeners for supported events
      cardNumberInput.addEventListener("change", (...args) => {
        console.log("cardNumberInput changed", ...args);
      });

      cardNumberInput.focus();

      submitButton.addEventListener("click", async () => {
        // Validate your card input data
        const { valid } = await cardManager.validate();
        if (valid) {
          // Submit the card input data to Primer for tokenization
          await cardManager.submit();
        } else {
          console.log("Card Details Invalid!");
        }
      });
    }

    // Configure headless
    await headless.configure({
      onAvailablePaymentMethodsLoad(paymentMethods) {
        // Called when the available payment methods are retrieved
        console.log(paymentMethods);

        for (const paymentMethod of paymentMethods) {
          // `type` is a unique ID representing the payment method
          const { type, managerType } = paymentMethod;

          switch (managerType) {
            case "CARD": {
              // Unhide the card-container div
              //container.style.display = 'flex'
              //container.style.flexDirection = 'column'
              //container.style.gap = '16px'
              // Configure your card form (see Step 4.a)
              console.log("Configuring Card Forms");
              configureCardForm(headless, paymentMethod);
              break;
            }
            case "NATIVE": {
              // Render the native payment method button (see Step 4.b)
              // Relevant for PayPal, Apple Pay, and Google Pay
              //console.log('Configuring ' + paymentMethod.type /*Native Payment Methods..."*/)
              //configureNativeButton(headless, paymentMethod)
              break;
            }
            case "REDIRECT": {
              // Handle redirect payment methods (see Step 4.c)
              console.log(
                "Configuring " +
                  paymentMethod.typeRedirect /*Payment Methods..."*/
              );
              configureRedirectPaymentMethod(headless, paymentMethod);
              break;
            }

            // More payment methods to follow
          }
        }
      },

      onCheckoutComplete({ payment }) {
        // Notifies you that a payment was created
        // Move on to the next step in your checkout flow:
        // e.g., Show a success message, giving access to the service, fulfilling the order, ...
        console.log("onCheckoutComplete", payment);
        alert('hot to trot');
        window.close();
      },

      onCheckoutFail(error, { payment }, handler) {
        // Notifies you that the checkout flow has failed and a payment could not be created
        // This callback can also be used to display an error state within your own UI.

        // ⚠️ `handler` is undefined if the SDK does not expect anything from you
        if (!handler) {
          return;
        }

        // ⚠️ If `handler` exists, you MUST call one of the functions of the handler

        // Show a default error message
        return handler.showErrorMessage();
      },
    });

    console.log("about to start");
    // Start the headless checkout

    await headless.start();
    console.log("Headless Universal Checkout is loaded!");
  };

  useEffect(() => {
    (async () => {
      const fetchPaymentToken = async () => {
        const options = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        };
        const result = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/public/invoice/${params.uuid}/pay`,
          options
        );
        const resultJSON = await result.json();

        //setPrimerClientToken(resultJSON.clientToken);
        //console.log(resultJSON.clientToken);

        await startPrimer(resultJSON.clientToken);
      };

      await fetchPaymentToken();
    })();
  }, [params.uuid]);

  /*
   *  Formatting the Currency amounts using native javascript
   *  constructs...
   */
  const total = invoice?.line_items
    .reduce((previous, current) => {
      return (
        previous +
        current.quantity *
          current.unit_price *
          (parseFloat(current.tax_rate ?? 0) === 0
            ? 1
            : (parseFloat(current.tax_rate) + 1) / 10)
      );
    }, 0)
    .toFixed(2);

  const stripeTotal = Math.round(total * 100);

  /*
   *  Payment method selection:
   *
   */
  const invoiceIsPaid =
    invoice?.status === "PAID" || invoice?.status === "EXPENSE_PAID";

  const showBSBAccountNumber = invoice?.remittance.external_bsb;

  return (
    <>
      {invoiceIsPaid ? (
        <>
          <div className="pl-lg-4">
            <Row className="justify-content-md-center mt-5">
              <Col xs={12} sm={12}>
                <p className="text-center">
                  <BigTick />
                </p>
                <h3 className="text-center">
                  {invoice?.status === "EXPENSE_PAID"
                    ? "This Expense has been marked as Paid"
                    : "Invoice has been Paid, Thank You!"}
                </h3>
                <br />
                <br />
              </Col>
            </Row>
          </div>
        </>
      ) : undefined}
      <div className="pl-lg-4 mt-3">
        <p>
          <strong>Payment:</strong>
        </p>
      </div>
      <div className="p-sm-5">
        <div
          id="card-container"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <div
            id="card-flex-row"
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              id="card-flex-col"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            ></div>
          </div>
          <div
            id="cardholder-flex-row"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              id="cardholder-flex-item"
              style={{ width: "100%", marginTop: "5px" }}
            ></div>
          </div>
          <div
            id="action-flex-row"
            style={{ textAlign: "right", marginTop: "15px" }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default LiveInvoiceMonoovaPay;
