import { getAuthorization } from "webauthn/session";
import { getPasskeyJWTAsync } from "../../../webauthn/securityStepUp";

export const getBusinessProfile = async () => {
  const authorization = await getAuthorization();
  const response = await fetch(
    `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/business-profile`,
    {
      headers: {
        Authorization: authorization,
      },
    }
  );
  const json = await response.json();

  return json;
};

export const getNextInvoiceSerial = async () => {
  const authorization = await getAuthorization();
  const response = await fetch(
    `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/invoice/propose-serial`,
    {
      headers: {
        Authorization: authorization,
      },
    }
  );
  const json = await response.json();

  return json.proposed_invoice_serial;
};

export const getInvoiceByUUID = async (uuid) => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  if (principalLegalEntity?.legal_entity_uuid) {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/invoice/${uuid}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
          "x-principal-legal-entity-uuid":
            principalLegalEntity.legal_entity_uuid,
        },
      }
    );
    const json = await response.json();

    return json.invoice;
  } else {
    const authorization = await getAuthorization();
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/invoice/${uuid}`,
      {
        headers: {
          Authorization: authorization,
        },
      }
    );
    const json = await response.json();

    return json.invoice;
  }
};

export const getMerchantLogo = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/document`,
    {
      headers: {
        Authorization: await getAuthorization(),
      },
    }
  );
  const json = await response.json();
  const logos = json.documents.filter((item) => item.category_type === "logo");

  if (logos[0]) {
    const logoDownloadResponse = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/document-download`,
      {
        method: "post",
        headers: {
          Authorization: await getAuthorization(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          s3_object_key: logos[0].object_key,
        }),
      }
    );
    const logoDownloadJSON = await logoDownloadResponse.json();

    return logoDownloadJSON.presigned_url;
  }
};

export const sendTest = async ({
  invoiceUUID,
  customerContactUUID,
  counterpartyUUID,
  invoiceDate,
  invoiceNumber,
  lineItems,
  invoiceNotes,
  externalBSB,
  externalAccountNumber,
}) => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  if (principalLegalEntity?.agent_legal_entity?.customer_uuid) {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/invoice/send-test`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
          "x-principal-legal-entity-uuid":
            principalLegalEntity.legal_entity_uuid,
        },
        body: JSON.stringify({
          invoice_uuid: invoiceUUID,
          customer_contact_uuid: customerContactUUID,
          counterparty_uuid: counterpartyUUID,
          invoice_date: invoiceDate,
          invoice_number: invoiceNumber,
          line_items: lineItems,
          notes: invoiceNotes,
          external_bsb: externalBSB,
          external_account_number: externalAccountNumber,
        }),
      }
    );
    const result = await response.json();

    alert("Test sent successfully");

    return result;
  } else {
    const authorization = await getAuthorization();
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/invoice/send-test`,
      {
        method: "post",
        headers: {
          Authorization: authorization,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          invoice_uuid: invoiceUUID,
          customer_contact_uuid: customerContactUUID,
          invoice_date: invoiceDate,
          invoice_number: invoiceNumber,
          line_items: lineItems,
          notes: invoiceNotes,
          external_bsb: externalBSB,
          external_account_number: externalAccountNumber,
        }),
      }
    );
    const result = await response.json();

    alert("Test sent successfully");

    return result;
  }
};

export const saveDraft = async ({
  invoiceUUID,
  customerContactUUID,
  counterpartyUUID,
  invoiceDate,
  invoiceNumber,
  lineItems,
  invoiceNotes,
  externalBSB,
  externalAccountNumber,
}) => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  if (principalLegalEntity?.agent_legal_entity?.customer_uuid) {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/invoice/save-draft`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
          "x-principal-legal-entity-uuid":
            principalLegalEntity.legal_entity_uuid,
        },
        body: JSON.stringify({
          invoice_uuid: invoiceUUID,
          counterparty_uuid: counterpartyUUID,
          invoice_date: invoiceDate,
          invoice_number: invoiceNumber,
          line_items: lineItems,
          notes: invoiceNotes,
          external_bsb: externalBSB,
          external_account_number: externalAccountNumber,

          customer_contact_uuid: customerContactUUID,
        }),
      }
    );
    const result = await response.json();

    return result;
  } else {
    const authorization = await getAuthorization();
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/invoice/save-draft`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
        },
        body: JSON.stringify({
          invoice_uuid: invoiceUUID,
          customer_contact_uuid: customerContactUUID,
          invoice_date: invoiceDate,
          invoice_number: invoiceNumber,
          line_items: lineItems,
          notes: invoiceNotes,
          external_bsb: externalBSB,
          external_account_number: externalAccountNumber,
        }),
      }
    );
    const result = await response.json();

    return result;
  }
};

export const sendNow = async ({
  invoiceUUID,
  customerContactUUID,
  invoiceDate,
  invoiceNumber,
  lineItems,
  invoiceNotes,
  externalBSB,
  externalAccountNumber,
}) => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  if (principalLegalEntity?.agent_legal_entity?.customer_uuid) {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/invoice/send-now`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
          "x-principal-legal-entity-uuid":
            principalLegalEntity.legal_entity_uuid,
        },
        body: JSON.stringify({
          invoice_uuid: invoiceUUID,
          customer_contact_uuid: customerContactUUID,
          invoice_date: invoiceDate,
          invoice_number: invoiceNumber,
          line_items: lineItems,
          notes: invoiceNotes,
          external_bsb: externalBSB,
          external_account_number: externalAccountNumber,
        }),
      }
    );
    const result = await response.json();

    alert("Invoice sent successfully");

    return result;
  } else {
    const authorization = await getAuthorization();
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/invoice/send-now`,
      {
        method: "post",
        headers: {
          Authorization: authorization,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          invoice_uuid: invoiceUUID,
          customer_contact_uuid: customerContactUUID,
          invoice_date: invoiceDate,
          invoice_number: invoiceNumber,
          line_items: lineItems,
          notes: invoiceNotes,
          external_bsb: externalBSB,
          external_account_number: externalAccountNumber,
        }),
      }
    );
    const result = await response.json();

    alert("Invoice sent successfully");

    return result;
  }
};

export const sendNewInvoice = async ({
  customerContactUUID,
  invoiceDate,
  invoiceNumber,
  lineItems,
  invoiceNotes,
  externalBSB,
  externalAccountNumber,
}) => {
  const authorization = await getAuthorization();
  const response = await fetch(
    `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/invoice/send`,
    {
      method: "post",
      headers: {
        Authorization: authorization,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_contact_uuid: customerContactUUID,
        invoice_date: invoiceDate,
        invoice_number: invoiceNumber,
        line_items: lineItems,
        notes: invoiceNotes,
        external_bsb: externalBSB,
        external_account_number: externalAccountNumber,
      }),
    }
  );
  await response.json();

  alert("Invoice sent successfully");
};

export const createDraft = async ({
  invoiceStatus,
  customerContactUUID,
  invoiceDate,
  invoiceNumber,
  lineItems,
  invoiceNotes,
  externalBSB,
  externalAccountNumber,
}) => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  if (principalLegalEntity?.agent_legal_entity?.customer_uuid) {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/invoice/draft`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
          "x-principal-legal-entity-uuid":
            principalLegalEntity.legal_entity_uuid,
        },
        body: JSON.stringify({
          invoice_status: invoiceStatus,
          customer_contact_uuid: customerContactUUID,
          invoice_date: invoiceDate,
          invoice_number: invoiceNumber,
          line_items: lineItems,
          notes: invoiceNotes,
          external_bsb: externalBSB,
          external_account_number: externalAccountNumber,
        }),
      }
    );

    await response.json();
  } else {
    const authorization = await getAuthorization();
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/invoice/draft`,
      {
        method: "post",
        headers: {
          Authorization: authorization,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          invoice_status: invoiceStatus,
          customer_contact_uuid: customerContactUUID,
          invoice_date: invoiceDate,
          invoice_number: invoiceNumber,
          line_items: lineItems,
          notes: invoiceNotes,
          external_bsb: externalBSB,
          external_account_number: externalAccountNumber,
        }),
      }
    );

    await response.json();
  }
};

export const updateDraft = async ({
  invoiceUUID,
  invoiceStatus,
  customerContactUUID,
  invoiceDate,
  invoiceNumber,
  lineItems,
  invoiceNotes,
  externalBSB,
  externalAccountNumber,
}) => {
  const authorization = await getAuthorization();
  const response = await fetch(
    `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/invoice/${invoiceUUID}`,
    {
      method: "put",
      headers: {
        Authorization: authorization,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        invoice_status: invoiceStatus,
        customer_contact_uuid: customerContactUUID,
        invoice_date: invoiceDate,
        invoice_number: invoiceNumber,
        line_items: lineItems,
        notes: invoiceNotes,
        external_bsb: externalBSB,
        external_account_number: externalAccountNumber,
      }),
    }
  );
  await response.json();
};

export const send = async ({ invoiceUUID }) => {
  const authorization = await getAuthorization();
  const response = await fetch(
    `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/invoice/${invoiceUUID}/send`,
    {
      method: "post",
      headers: {
        Authorization: authorization,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    }
  );
  await response.json();

  alert("Invoice sent successfully");
};

export const getMerchantsCustomerList = async () => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  if (principalLegalEntity?.agent_legal_entity?.customer_uuid) {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/counterparty`,
      {
        headers: {
          Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
          "x-principal-legal-entity-uuid":
            principalLegalEntity.legal_entity_uuid,
        },
      }
    );

    const json = await response.json();

    const options = json.counterparties
      .filter((counterparty) => !counterparty.supplier)
      .map((counterparty) => {
        const label = counterparty.business_name
          ? counterparty.business_name
          : `${counterparty.first_name ?? ""} ${counterparty.last_name ?? ""}`;
        return {
          value: counterparty.uuid,
          label: label,
        };
      });

    return options;
  } else {
    const authorization = await getAuthorization();
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/contact`,
      {
        headers: {
          Authorization: authorization,
        },
      }
    );

    const json = await response.json();

    const options = json.contacts.map((contact) => {
      const label = contact.business_name
        ? contact.business_name
        : `${contact.first_name ?? ""} ${contact.last_name ?? ""}`;
      return {
        value: contact.uuid,
        label: label,
      };
    });

    return options;
  }
};

export const loadAsyncSelectMerchantsCustomerList = async (
  inputValue,
  callback
) => {
  const options = await getMerchantsCustomerList();

  callback(options);
};

const adjustLineItemArrayLength = (currentEditingIndex, lineItems) => {
  if (
    (lineItems[currentEditingIndex].description ||
      lineItems[currentEditingIndex].quantity ||
      lineItems[currentEditingIndex].unit_price) &&
    currentEditingIndex === lineItems.length - 1
  ) {
    lineItems.push({
      description: "",
      quantity: null,
      unit_price: null,
      tax_rate: "0",
    });
  }

  if (
    lineItems.length > 0 &&
    !lineItems[lineItems.length - 2].description &&
    !lineItems[lineItems.length - 2].quantity &&
    !lineItems[lineItems.length - 2].unit_price &&
    !lineItems[lineItems.length - 1].description &&
    !lineItems[lineItems.length - 1].quantity &&
    !lineItems[lineItems.length - 1].unit_price
  ) {
    lineItems.pop();
  }
};

export const lineItemDescriptionChange = (
  invoiceLineItems,
  setInvoiceLineItems,
  index,
  newDescription
) => {
  let lineItems = [...invoiceLineItems];
  lineItems[index].description = newDescription;

  adjustLineItemArrayLength(index, lineItems);

  setInvoiceLineItems(lineItems);
};

export const lineItemQuantityChange = (
  invoiceLineItems,
  setInvoiceLineItems,
  index,
  newQuantity
) => {
  let lineItems = [...invoiceLineItems];
  lineItems[index].quantity = newQuantity;

  adjustLineItemArrayLength(index, lineItems);

  setInvoiceLineItems(lineItems);
};

export const lineItemUnitPriceChange = (
  invoiceLineItems,
  setInvoiceLineItems,
  index,
  newUnitPrice
) => {
  let lineItems = [...invoiceLineItems];
  lineItems[index].unit_price = newUnitPrice;

  adjustLineItemArrayLength(index, lineItems);

  setInvoiceLineItems(lineItems);
};

export const lineItemGSTChange = (
  invoiceLineItems,
  setInvoiceLineItems,
  index
) => {
  const newGST =
    parseFloat(invoiceLineItems[index].tax_rate) === 10 ? "0" : "10";

  let lineItems = [...invoiceLineItems];
  lineItems[index].tax_rate = newGST;

  setInvoiceLineItems(lineItems);
};
